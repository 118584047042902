/*
 * Copyright Robert Bosch GmbH. Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, editing, distribution, as well as in the event of applications for industrial property rights.
 */
.ParkingFacilitiesServices {
  &__wrapper {
    border: 1px solid #eee;
    box-shadow: 0 2px 3px #ccc;
    padding: 0;
    margin: 0;
    border-radius: 5px;
    margin-bottom: 50px;

    &__heading {
      background-color: #f2f2f0;
      padding: 2px 20px;

      &__title {
        color: #000;
        font-size: 20px;
        font-weight: 600;
      }
    }

    &__container {
      width: 100%;

      &__box {
        width: 90%;
        margin: 0 auto;

        &__left {
          background-color: #fff;
          margin: 30px;

          &__facalities {
            margin: 20px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
          }
        }

        &__right {
          background-color: #fff;
          margin: 30px;

          &__facalities {
            background: #f2f2f0;
            padding: 25px;
            margin: 20px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
          }
        }
      }
    }
  }
}

.EditParkingStepThree {
  &__wrapper {
    width: 95%;
    padding: 0;
    border: 1px solid #eee;
    box-shadow: 0 2px 3px #ccc;
    padding: 0;
    margin: 0 auto;
    border-radius: 5px;
    margin-bottom: 50px;

    &__heading {
      padding: 10px 20px;
      background-color: rgba(226, 220, 242, 0.38);

      &__title {
        color: #21034f;
        font-size: 20px;
        font-weight: 600;
        padding-top: 15px;
      }
    }

    &__container {
      display: flex;
      justify-content: space-around;
      padding-bottom: 35px;
      padding-top: 5px;

      &__leftside {
        width: 30%;
      }
      &__rightside {
        width: 55%;
      }
    }
  }
}

.ParkingFacilities {
  &__wrapper {
    &_heading {
      font-size: 15px;
      font-weight: 700;
      padding: 30px 0px 15px 0px;
    }

    &_box {
      &_search {
        position: relative;
        margin-bottom: 25px;

        .MuiSvgIcon-root {
          position: absolute;
          top: 8px;
          z-index: 1;
          right: 10px;
        }

        .MuiInputBase-root {
          width: 100%;
          margin: 0 auto;
        }

        .MuiInputBase-root input {
          background: #f2f2f0;
          border-radius: 20px;
          padding-left: 15px;
        }
      }

      &_facilities {
        display: flex;
        justify-content: space-between;

        &_left {
          display: flex;
          width: 330px;
          margin-bottom: 7px;

          &_text {
            margin-top: 10px;
            width: 200px;
          }
        }
      }
    }
  }
}

.button__wrapper {
  display: inline-flex;
  margin-top: 10px;
  margin-right: 10px;
  width: calc((100% - 26px) / 2);
  align-items: center;
  border: 1px solid black;
  padding: 6px 20px;
  min-height: 50px;
  margin-bottom: 15px;
  border-radius: 5px;
  cursor: pointer;

  &_left {
    img {
      width: 40px;
      height: 40px;
      vertical-align: middle;
    }
  }
  &_right {
    padding-left: 10px;
    p {
      font-size: 12px;
      font-weight: 600;
      margin-bottom: 5px;
    }
    p.desc {
      font-size: 12px;
      color: #102e35;
      margin-bottom: 0;
    }
    p.desc1 {
      font-size: 7px;
    }
  }
  &:nth-child(2n) {
    margin-right: 15px;
  }
  &.active {
    background: #e46a76;
    color: white;
    .button__wrapper_right {
      p.desc {
        color: white;
      }
    }
  }
}
