/*
 * Copyright Robert Bosch GmbH. Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, editing, distribution, as well as in the event of applications for industrial property rights.
 */
.app-footer {
  margin-left: 0px !important;
  background-color: #01c0cb;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 1em;
  word-spacing: 5px;
  color: white;
  // border-top: 1px solid #a4b7c1;
}

.popUp {
  position: absolute;
  width: 70%;
  margin: 0 auto;
  z-index:1;
  background: #fff;
  padding: 20px;
  border: 1px solid;
  border-radius: 10px;
  top: 145px;
  color: black;
  left: 250px;
  padding-top: 0px !important;
}
.wrapper .h1 {
  margin-top: 11px;
}
.Cancel {
  padding: 6px 16px;
  min-width: 64px;
  border-radius: 20px;
  font-size: 11px;
  background-color: #02a6dc !important;
  color: #fff !important;
  margin-top: 20px !important;
}

.footer_list li {
  display: inline;
  list-style: none;
  cursor: pointer;
  padding-left: 15px;
}
