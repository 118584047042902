.logo {
    padding-top: 1rem;
}
.tab-heading {
    font-size: 36px;
    line-height: 1.1;
    padding-bottom: 1rem;
    margin-top: 20px;
    font-family: "BoschSans-Light",Arial,Helvetica,sans-serif !important;
    letter-spacing: -0.02em;
    border-bottom: 1px solid hsl(0, 0%, 87%)!important;
    font-weight: 100;

}
.title {
    font-weight: 100;
}
p {
    font-family: "BoschSans-Light",Arial,Helvetica,sans-serif !important;
    font-size: 16px;
}
h2 {
    font-size: 24px;
    font-weight: 100;
}
h3 {
    color:#337ab7
}