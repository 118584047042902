/*
 * Copyright Robert Bosch GmbH. Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, editing, distribution, as well as in the event of applications for industrial property rights.
 */
body,
p,
h2,
span,
.MuiButton-root,
.MuiTypography-h6,
.MuiTypography-body1,
.MuiTypography-h5,
.MuiInputBase-input {
  margin: 0;
  font-family: 'Poppins'  !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.MuiTypography-h6 {
  font-size: 1.03rem !important;
  font-weight: 700 !important;
}
body {
  overflow: auto;
}
.MuiPaper-rounded {
  border-radius: 10px !important;
}
.MuiButton-label {
  font-weight: bold;
}

.MuiPaper-elevation8 {
  box-shadow: 0px 3px 6px #0000001a !important;
}
