/*
 * Copyright Robert Bosch GmbH. Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, editing, distribution, as well as in the event of applications for industrial property rights.
 */
.MuiAppBar-colorPrimary {
  color: #fff;
  background-color: #01C0CB;
}

.hamburger-icon, .header-icon {
  width: 65%;
}

img.logo {
  width: 75%;
}

.login_detials {
  margin: auto;
  position: absolute;
  right: 25px;
  font-size: 10px;
  letter-spacing: 1px;
  visibility: hidden;
}

.head-alert, .para-alert {
  margin-top: 0px;
}

.MuiListItemIcon-root {
  margin-left: 10px;
}

.optionsContainer {
  width: 100%;
  transition: width 0.3s;
  position: absolute;
  padding-left: 0;
  border-radius: 15px;
  background-color: white;
}

ul.options {
  margin-left: -40px;
}

ul.options li {
  display: block;
  background: white;
  border-bottom: 1px solid;
  padding: 10px;
  color: black;
  width: 100%;
}
ul.options li:hover {
  color: black;
  background: whitesmoke;
  cursor: pointer;
  transition: 0.3s all;
}

ul.options li.option-active {
  background: whitesmoke;
}
.no-options {
  color: black;
  margin: 10px;
}
button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.logoutBtn {
  background-color: #e46767;
  color: #fff;
  border-radius: 20px;
  margin: auto;
  font-size: 10px;
  padding-left: 25px;
  padding-right: 25px;
}
p.link {
  font-weight: bolder;
  color: #41C1CB;
  font-size: 10px !important;
}
.listItem {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.listItem p{
  font-size: 10px !important;
}
.MuiBadge-colorSecondary {
  color: #01C0C8 !important;
  background-color: #fff !important;
}
.makeStyles-sectionDesktop-6{
  .MuiButton-text {
padding: 5px 15px !important;
  }
}
@media (min-width: 600px){
  .MuiToolbar-regular {
min-height: 55px !important;
  }
}
#primary-search-account-menu{
  position: absolute!important;
}
.searchBoxStyle {
  width: 50%;
}
.profileViewer {
  a {
    color: #41C1CB !important;
  }
}
.searchBoxStyle {
  .Mui-disabled {
    background-color: transparent !important;
}
}